import {
  type HttpErrorResponse,
  type HttpEvent,
  type HttpHandler,
  type HttpInterceptor,
  type HttpRequest
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ConfigProperty, ConfigService } from '@sds/shared';
import { type Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class MigrationTokenReloadInterceptor implements HttpInterceptor {
  private readonly configService = inject(ConfigService);
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error.status === 401 &&
          this.configService.get(ConfigProperty.TokenEndpoint) ===
            'https://app-api-token-r-euwe-19e26c.azurewebsites.net'
        ) {
          location.reload();
        }
        return next.handle(request);
      })
    );
  }
}
