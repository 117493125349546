import { type RouterLinkActive } from '@angular/router';
import { type Permission } from '@sds/permissions';

export class MenuItem {
  name: string;
  icon: string;
  route: string;
  parent?: MenuItem;
  subItems: MenuItem[];
  routerLinkActiveOptions: RouterLinkActive | { exact: boolean };
  requiredPermissions?: Array<Permission>;
  id: string;

  constructor(model: Partial<MenuItem>) {
    if (model) {
      this.name = model.name ?? '';
      this.icon = model.icon ?? '';
      this.route = model.route!;
      this.parent = model.parent;
      this.subItems = model && model.subItems && model.subItems.length ? this.mapSubItems(model.subItems) : [];
      this.routerLinkActiveOptions =
        model && model.routerLinkActiveOptions ? model.routerLinkActiveOptions : { exact: false };
      this.requiredPermissions = model.requiredPermissions;
      this.id = model.id ?? '';
    }
  }

  hasSubItems() {
    return this.subItems && this.subItems.length;
  }

  hasParent() {
    return !!this.parent;
  }

  mapSubItems(list: MenuItem[]) {
    if (list) {
      list.forEach((item, index) => {
        list[index] = new MenuItem(item);
      });

      return list;
    } else {
      return [];
    }
  }

  generateLetterIcon() {
    const words = this.name.split(' ');

    if (words.length > 1) {
      return words[0].charAt(0).toUpperCase() + words[1].charAt(0).toLowerCase();
    } else {
      return this.name.charAt(0).toUpperCase() + this.name.charAt(1).toLowerCase();
    }
  }
}
