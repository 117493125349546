import {
  type HttpEvent,
  type HttpHandler,
  type HttpInterceptor,
  HttpParams,
  type HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { type Observable } from 'rxjs';

@Injectable()
export class PublicPinboardInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<never>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (window.location.href.includes('public/pinboard') && req.url.includes('widget/widget')) {
      const splitUrl = window.location.href.split('/');
      return next.handle(
        req.clone({
          params: (req.params ?? new HttpParams()).set('pinboardId', splitUrl[splitUrl.length - 1])
        })
      );
    }

    return next.handle(req);
  }
}
