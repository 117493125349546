import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { APP_INITIALIZER, makeEnvironmentProviders } from '@angular/core';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';
import { MAT_CARD_CONFIG, type MatCardConfig } from '@angular/material/card';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, type MatDialogConfig } from '@angular/material/dialog';
import { MAT_EXPANSION_PANEL_DEFAULT_OPTIONS } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, type MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatIconRegistry } from '@angular/material/icon';
import { MAT_SELECT_CONFIG, type MatSelectConfig } from '@angular/material/select';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_TABS_CONFIG, type MatTabsConfig } from '@angular/material/tabs';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, type MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { registerSvgIcons } from './register-svg-icons.factory';

export function provideMaterial() {
  return makeEnvironmentProviders([
    {
      provide: MAT_CARD_CONFIG,
      useValue: {
        appearance: 'raised'
      } as MatCardConfig
    },
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {
        hideSingleSelectionIndicator: true
      } as MatSelectConfig
    },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 4000 } },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: 200,
        hideDelay: 200,
        touchendHideDelay: 1000,
        position: 'above',
        disableTooltipInteractivity: true
      } as MatTooltipDefaultOptions
    },
    { provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, useValue: { autoActiveFirstOption: true } },
    {
      provide: MAT_EXPANSION_PANEL_DEFAULT_OPTIONS,
      useValue: {
        hideToggle: false,
        expandedHeight: '48px',
        collapsedHeight: '48px'
      }
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', subscriptSizing: 'dynamic' } as MatFormFieldDefaultOptions
    },
    {
      provide: MAT_TABS_CONFIG,
      useValue: {
        fitInkBarToContent: false,
        preserveContent: false,
        stretchTabs: false
      } as MatTabsConfig
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        disableClose: true
      } as MatDialogConfig
    },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: registerSvgIcons,
      deps: [MatIconRegistry, DomSanitizer]
    },
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer }
  ]);
}
