import { type MatIconRegistry } from '@angular/material/icon';
import { type DomSanitizer } from '@angular/platform-browser';

export function registerSvgIcons(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
  return () =>
    new Promise<void>(resolve => {
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'sds-logo',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/sds/sds_logo.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'sds-logo-dark-bg',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/sds/sds_logo_dark_bg.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'error-robot',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/error_robot.svg')
      );

      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'administration',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/menu/administration.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'user-management',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/menu/user.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'plant-management',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/menu/plant.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'fleet-management',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/menu/fleet.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'monitoring',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/menu/monitoring.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'dashboard',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/menu/dashboard.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'task-list',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/menu/tasklist.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'portals',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/menu/portals.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'translations',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/menu/translations.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'development',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/menu/development.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'red-dot',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/red_dot.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'green-dot',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/green_dot.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'orange-dot',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/orange_dot.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'yellow-dot',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/yellow_dot.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'grey-dot',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/grey_dot.svg')
      );

      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'home',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/menu/home.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'invoicing',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/menu/invoicing.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'market-place',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/menu/market_place.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'portfolio',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/menu/portfolio.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'remote-configuration',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/menu/remote_configuration.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'reporting',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/menu/reporting.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'drag',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/menu/drag.svg')
      );

      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'circle-placeholder',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/placeholders/circle-placeholder.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'component-placeholder',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/placeholders/components-placeholder.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'contact-finance-document-placeholder',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/placeholders/contact-finance-document-placeholder.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'under-construction',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/placeholders/under-construction.svg')
      );

      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'status-active',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/status_active.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'status-inactive',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/status_inactive.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'mark-green',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/mark-green.svg')
      );

      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'logan',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/users/logan.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'user-active',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/users/user_active.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'user-group-active',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/users/user_group.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'user-profile-icon',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/users/user_profile_icon.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'user-profile-pic',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/users/user_profile_pic.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'user-profile-avatar',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/users/user_profile_avatar.svg')
      );

      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'arrow-down',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/ag-grid/arrow-down.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'sort-asc',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/ag-grid/arrow-down-01.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'sort-desc',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/ag-grid/arrow-up-01.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'checkbox',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/ag-grid/checkbox.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'checkbox-checked',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/ag-grid/users/checkbox-checked.svg')
      );

      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'bluetooth',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/bluetooth.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'pm+',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/pm+.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'gprs',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/gprs.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'wifi',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/wifi.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'fleet',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/fleet.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'direct-marketing',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/recepient.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'pm',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/pm_dpm.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'angle',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/angle.svg')
      );

      // plant components clases and types
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'signal-disconnected',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/signal_disconnected.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'component-list',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/component_list.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'module',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/module.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'inverter',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/Inverter.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'scb',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/scb.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'ev-charger',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/ev-charger.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'meter',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/meter.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'meter-as-inverter',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/meter_as_inverter.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'revenue-grade-meter',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/revenue_grade_meter.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'bi-directional-meter',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/bi_directional_meter.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'smart-consumption-meter',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/smart_consumption_meter.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'smart-subconsumption-meter',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/smart_subconsumption_meter.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'pyranometer',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/pyranometer.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'sensor',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/sensor.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'battery',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/battery.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'hybridsystem',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/hybridsystem.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'solar-heat',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/solar_heat.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'unknown-device',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/unknown_device.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'utility-net-meter-ui',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/utility_net_meter_ui.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'no-production',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/no_production.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'heatpump',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/heatpump.svg')
      );

      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'mpp-tracker',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/mpp-tracker.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'string',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/string.svg')
      );

      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'contacts',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/contact.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'finance',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/finance.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'documents',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/documents.svg')
      );

      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'recepient',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/recepient.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'device-not.',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/device_notification.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'power-deviation-alarm',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/power.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'i/o',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/I_O.svg')
      );

      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'pm/dpm',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/pm_dpm.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'smart-energy',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/smart_energy.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'regional-comparison',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/pm_dpm.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'deviation-alarm',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/plants/meter.svg')
      );

      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'component-communication',
        domSanitizer.bypassSecurityTrustResourceUrl(
          'assets/img/monitoring-plant-errors-list/component-communication.svg'
        )
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'data-transfer',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/monitoring-plant-errors-list/data-transfer.svg')
      );

      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'deviation',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/monitoring-plant-errors-list/deviation.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'power-management',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/monitoring-plant-errors-list/power-management.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'status-error',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/monitoring-plant-errors-list/status-red.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'drag',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/monitoring-plant-errors-list/drag.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'return',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/monitoring-plant-errors-list/return.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'option',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/monitoring-plant-errors-list/option.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'expand',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/monitoring-plant-errors-list/expand.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'ongoing',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/monitoring-plant-errors-list/ongoing-errors.svg')
      );

      // task-list
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'attachment',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/task-list/paper_clip.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'attachment-mini',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/task-list/attachment_mini.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'no-filter',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/task-list/no_filter.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'email',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/task-list/email.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'back-to-monitoring',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/task-list/back_to_monitoring.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'plant-location',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/task-list/plant_location.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'option-up',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/task-list/option_up.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'option-down',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/task-list/option_down.svg')
      );

      // plant LCD
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'empty',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/icon-empty.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'clock',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/clock.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'battery-level0',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/lcd_icons/battery-level0.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'battery-level1',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/lcd_icons/battery-level1.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'battery-level2',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/lcd_icons/battery-level2.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'battery-level3',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/lcd_icons/battery-level3.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'signal-level1',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/lcd_icons/signal-level1.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'signal-level2',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/lcd_icons/signal-level2.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'signal-level3',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/lcd_icons/signal-level3.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'signal-level4',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/lcd_icons/signal-level4.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'signal-level5',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/lcd_icons/signal-level5.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'easy',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/lcd_icons/easy.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'e-mail',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/lcd_icons/e-mail.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'lcd-gprs',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/lcd_icons/gprs.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'internet',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/lcd_icons/internet.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'percent',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/lcd_icons/prozent.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'key',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/lcd_icons/key.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'sim-card',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/lcd_icons/sim-karte.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'grey-clock',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/lcd_icons/uhr.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'lcd-inverter',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/lcd_icons/inverter.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'lcd-wifi',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/lcd_icons/wifi.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'counter',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/lcd_icons/counter.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'windmill',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/lcd_icons/windrad.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'roof',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/lcd_icons/roof.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'sun',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/lcd_icons/sonne.svg')
      );

      // languages
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'en-GB',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/languages/great_britain.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'en-US',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/languages/usa.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'de-DE',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/languages/germany.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'lol-US',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/languages/rainbow-flag.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'fr-FR',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/languages/fr-FR.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'es-ES',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/languages/es-ES.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'it-IT',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/languages/it-IT.svg')
      );

      // analysis chart placeholders
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'chart-deviation',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/placeholders/chart-deviation.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'chart-normalized',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/placeholders/chart-normalized.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'chart-udc-dc',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/placeholders/chart-udc-dc.svg')
      );

      // export
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'csv',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/csv.svg')
      );

      // ecar
      matIconRegistry.addSvgIconInNamespace(
        'sds',
        'e-car',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/e-car/e-auto.svg')
      );

      // solar log device
      matIconRegistry.addSvgIconInNamespace(
        'sds',
        'sl-base',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/sl-device/sl_base.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sds',
        'sl-outline',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/sl-device/sl_base_outline.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sds',
        'sl-mod-485',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/sl-device/sl_mod_485.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sds',
        'sl-mod-io',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/sl-device/sl_mod_i_o.svg')
      );

      // Power Management
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'linear-interpolation',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/power-management/linear_interpolation.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'no-interpolation',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/power-management/no_interpolation.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'no-interpolation2',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/power-management/no_interpolation2.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'pt1-interpolation',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/power-management/pt1_interpolation.svg')
      );

      // Automation
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'alert',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/automation/alert.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'globe',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/automation/globe.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'light-bulb',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/automation/light-bulb.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'lightning',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/automation/lightning.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'plant',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/automation/plant.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'play',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/automation/play.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'send',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/automation/send.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'shuffle',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/automation/shuffle.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'blocks',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/automation/blocks.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'union',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/automation/union.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'eye',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/automation/eye.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'info',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/automation/info.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'date-clock',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/automation/date-clock.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'refresh',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/automation/refresh.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'condition',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/automation/condition.svg')
      );
      matIconRegistry.addSvgIconInNamespace(
        'sl',
        'check',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/img/automation/check.svg')
      );
      resolve();
    });
}
