import { type HttpEvent, type HttpHandler, type HttpInterceptor, type HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { type Observable } from 'rxjs';

@Injectable()
export class Oauth2Interceptor implements HttpInterceptor {
  private readonly oauthService = inject(OAuthService);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.oauthService.hasValidAccessToken() && !req.url.includes('openid')) {
      const token = 'Bearer ' + this.oauthService.getAccessToken();
      const newReq = req.clone({
        setHeaders: {
          Authorization: token
        }
      });

      return next.handle(newReq);
    }
    return next.handle(req);
  }
}
