import { type HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  AdministrationApiConfiguration,
  BillingApiConfiguration,
  DatasourceApiConfiguration,
  ErrorDetectionApiConfiguration,
  FFuFAutomationApiConfiguration,
  FFuFKeycloakUsersApiConfiguration,
  FFuFLogFilesApiConfiguration,
  FFuFQuotaStatisticsApiConfiguration,
  FilestorageApiConfiguration,
  IntervalImportApiConfiguration,
  ReportingApiConfiguration,
  SolarLogAdapterApiConfiguration,
  SolarLogInterchangeActivePowerService,
  SolarLogInterchangeComponentConfigurationService,
  SolarLogInterchangeComponentDetectionService,
  SolarLogInterchangeFirmwareUpdateService,
  SolarLogInterchangePlantParametersService,
  SolarLogInterchangeReactivePowerService,
  SolarLogInterchangeTelecontrolConfigurationService,
  TimelineApiConfiguration,
  VisualisationApiConfiguration,
  WeatherForecastApiConfiguration,
  WidgetsApiConfiguration
} from '@sds/api';
import { type PortalEntity, type UserStorageEntity, type UserUserMe } from '@sds/api/administration/models';
import {
  AdministrationApiPortalService,
  AdministrationApiUserService,
  AdministrationApiUserStorageService
} from '@sds/api/administration/services';
import { NotificationService } from '@sds/api/ffuf-notifications/services';
import { type ProfitcenterField } from '@sds/api/ffuf-users/models';
import {
  FFUFUsersApiDeviceService,
  FFUFUsersApiProfitcenterService,
  FFUFUsersApiUserService
} from '@sds/api/ffuf-users/services';
import { type LandingPageEntity } from '@sds/api/widget/models';
import { WidgetsLandingPageService } from '@sds/api/widget/services';
import { ConfigProperty, ConfigService, LocaleStore, PortalSettingsStore, UserStore } from '@sds/shared';
import { Language, PassPortalFields, Theme } from '@sds/shared/enums';
import { handleJSON } from '@sds/shared/utils';
import { UserSetting, UserSettingsStore } from '@sds/user-settings';
import { browserTracingIntegration, init, replayIntegration, setUser } from '@sentry/angular';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { type Observable, catchError, filter, forkJoin, from, map, of, switchMap, tap, zip } from 'rxjs';
import { authConfig } from '../oauth2.config';

export function initializerFactory(oauthInitializerService: InitializerService, configService: ConfigService) {
  return () => from(configService.loadConfigurationData()).pipe(switchMap(() => oauthInitializerService.initialize()));
}

@Injectable({
  providedIn: 'root'
})
export class InitializerService {
  private router = inject(Router);
  private localeStore = inject(LocaleStore);
  private userStore = inject(UserStore);
  private userSettingsStore = inject(UserSettingsStore);
  private portalSettingsStore = inject(PortalSettingsStore);
  private configService = inject(ConfigService);
  private oauthService = inject(OAuthService);
  private oAuthStorage = inject(OAuthStorage);
  private portalService = inject(AdministrationApiPortalService);
  private passUserApiService = inject(FFUFUsersApiUserService);
  private passDeviceService = inject(FFUFUsersApiDeviceService);
  private passKeycloakUsersApiConfiguration = inject(FFuFKeycloakUsersApiConfiguration);
  private passProfitcenterApiService = inject(FFUFUsersApiProfitcenterService);
  private weatherForecastApiConfiguration = inject(WeatherForecastApiConfiguration);
  private solarLogAdapterApiConfiguration = inject(SolarLogAdapterApiConfiguration);
  private intervalImportApiConfiguration = inject(IntervalImportApiConfiguration);
  private userStorageService = inject(AdministrationApiUserStorageService);
  private ffufLogfilesApiConfigurationService = inject(FFuFLogFilesApiConfiguration);
  private fFuFQuotaStatisticsApiConfiguration = inject(FFuFQuotaStatisticsApiConfiguration);
  private ffufAutomationApiConfiguration = inject(FFuFAutomationApiConfiguration);
  private ffufNotificationService = inject(NotificationService);
  private widgetsLandingPageService = inject(WidgetsLandingPageService);
  private filestorageApiConfiguration = inject(FilestorageApiConfiguration);
  private solarLogInterchangeComponentDetectionService = inject(SolarLogInterchangeComponentDetectionService);
  private solarLogInterchangeComponentConfigurationService = inject(SolarLogInterchangeComponentConfigurationService);
  private solarLogInterchangeActivePowerService = inject(SolarLogInterchangeActivePowerService);
  private solarLogInterchangeReactivePowerService = inject(SolarLogInterchangeReactivePowerService);
  private solarLogInterchangePlantParametersService = inject(SolarLogInterchangePlantParametersService);
  private solarLogInterchangeTelecontrolConfigurationService = inject(
    SolarLogInterchangeTelecontrolConfigurationService
  );
  private administrationApiUserService = inject(AdministrationApiUserService);
  private administrationApiConfiguration = inject(AdministrationApiConfiguration);
  private billingApiConfiguration = inject(BillingApiConfiguration);
  private datasourceApiConfiguration = inject(DatasourceApiConfiguration);
  private errorDetectionApiConfiguration = inject(ErrorDetectionApiConfiguration);
  private reportingApiConfiguration = inject(ReportingApiConfiguration);
  private timelineApiConfiguration = inject(TimelineApiConfiguration);
  private visualisationApiConfiguration = inject(VisualisationApiConfiguration);
  private widgetsApiConfiguration = inject(WidgetsApiConfiguration);
  private solarLogInterchangeFirmwareUpdateService = inject(SolarLogInterchangeFirmwareUpdateService);

  initialize(): Observable<unknown> {
    this.resolveApiServiceRootUrls(this.configService);
    this.oauthService.configure({
      ...authConfig,
      clientId: this.configService.get(ConfigProperty.OauthClientId),
      issuer: this.configService.get(ConfigProperty.Issuer),
      tokenEndpoint: this.configService.get(ConfigProperty.TokenEndpoint),
      userinfoEndpoint: this.configService.get(ConfigProperty.UserApiUrl),
      loginUrl: this.configService.get(ConfigProperty.LoginUrl),
      logoutUrl: this.configService.get(ConfigProperty.LogoutUrl)
    });

    if (window.location.search.includes('token') && !this.oAuthStorage.getItem('access_token')) {
      const accessToken = window.location.search
        .substring(window.location.search.indexOf('token'))
        .replace('token=', '');
      this.oAuthStorage.setItem('access_token', accessToken);
    }

    return from(this.oauthService.loadDiscoveryDocumentAndTryLogin()).pipe(
      tap(() => this.oauthService.setupAutomaticSilentRefresh()),
      filter(() => {
        return this.oauthService.hasValidAccessToken();
      }),
      switchMap(() => zip([this.loadUser(), this.loadUserSettings()])),
      map(([user]) => user as UserUserMe),
      filter(user => !!user),
      switchMap(user => this.loadCurrentPortal(user.currentPortalId ?? '')),
      switchMap(portal => this.loadPortalSettings(portal.ffufId ?? 0)),
      tap(() => {
        if (this.userStore.user()) {
          this.initSentry(this.configService, this.userStore.user()!);
        }
      })
    );
  }

  private resolveApiServiceRootUrls(configService: ConfigService) {
    this.administrationApiConfiguration.rootUrl = configService.get(ConfigProperty.AdministrationApi);
    this.billingApiConfiguration.rootUrl = configService.get(ConfigProperty.BillingApi);
    this.datasourceApiConfiguration.rootUrl = configService.get(ConfigProperty.DatasourceApi);
    this.errorDetectionApiConfiguration.rootUrl = configService.get(ConfigProperty.ErrorDetectionApi);
    this.reportingApiConfiguration.rootUrl = configService.get(ConfigProperty.ReportingApi);
    this.timelineApiConfiguration.rootUrl = configService.get(ConfigProperty.TimelineApi);
    this.visualisationApiConfiguration.rootUrl = configService.get(ConfigProperty.VisualisationApi);
    this.widgetsApiConfiguration.rootUrl = configService.get(ConfigProperty.WidgetsApi);
    this.ffufNotificationService.rootUrl = configService.get(ConfigProperty.NotificationsApi);
    this.passUserApiService.rootUrl = configService.get(ConfigProperty.UserApiUrl);
    this.passDeviceService.rootUrl = configService.get(ConfigProperty.UserApiUrl);
    this.passProfitcenterApiService.rootUrl = configService.get(ConfigProperty.UserApiUrl);
    this.passKeycloakUsersApiConfiguration.rootUrl = configService.get(ConfigProperty.PasswordApiUrl);
    this.weatherForecastApiConfiguration.rootUrl = configService.get(ConfigProperty.WeatherDataApi);
    this.solarLogAdapterApiConfiguration.rootUrl = configService.get(ConfigProperty.SolarLogAdapterApi);
    this.ffufLogfilesApiConfigurationService.rootUrl = configService.get(ConfigProperty.LogfileApiUrl);
    this.fFuFQuotaStatisticsApiConfiguration.rootUrl = configService.get(ConfigProperty.QuotaApiUrl);
    this.ffufAutomationApiConfiguration.rootUrl = configService.get(ConfigProperty.AutomationApiUrl);
    this.intervalImportApiConfiguration.rootUrl = configService.get(ConfigProperty.IntervalImport);
    this.filestorageApiConfiguration.rootUrl = configService.get(ConfigProperty.FilestorageApi);
    this.solarLogInterchangeComponentDetectionService.rootUrl = configService.get(
      ConfigProperty.SolarLogInterchangeApi
    );
    this.solarLogInterchangeComponentConfigurationService.rootUrl = configService.get(
      ConfigProperty.SolarLogInterchangeApi
    );
    this.solarLogInterchangeActivePowerService.rootUrl = configService.get(ConfigProperty.SolarLogInterchangeApi);
    this.solarLogInterchangeReactivePowerService.rootUrl = configService.get(ConfigProperty.SolarLogInterchangeApi);
    this.solarLogInterchangePlantParametersService.rootUrl = configService.get(ConfigProperty.SolarLogInterchangeApi);
    this.solarLogInterchangeTelecontrolConfigurationService.rootUrl = configService.get(
      ConfigProperty.SolarLogInterchangeApi
    );
    this.solarLogInterchangeFirmwareUpdateService.rootUrl = configService.get(ConfigProperty.SolarLogInterchangeApi);
  }

  private initSentry(configService: ConfigService, user: UserUserMe) {
    const sentryUrl: string | null = configService.get(ConfigProperty.SentryDns);
    if (sentryUrl?.length) {
      setUser({ userId: user.id, email: user.primaryMail, username: user.displayName });
      init({
        dsn: sentryUrl,
        environment: window.location.hostname,
        release: `enerest-v4@${configService.get(ConfigProperty.VersionNumber) ?? '0.0.0'}`,
        replaysOnErrorSampleRate: 1,
        tracePropagationTargets: [window.location.origin, configService.get(ConfigProperty.BaseApiUrl)],
        tracesSampleRate: 1,
        ignoreTransactions: ['/print/report/:reportId/'],
        integrations: [
          browserTracingIntegration(),
          replayIntegration({
            // Additional SDK configuration goes in here, for example:
            maskAllText: true,
            blockAllMedia: true
          })
        ],
        beforeSend: (event, hint) => {
          const response = hint.originalException as HttpErrorResponse;

          if (response && response.status && [401, 403, 404].includes(response.status)) {
            return null;
          }
          return event;
        }
      });
    }
  }

  private loadUser(): Observable<UserUserMe | null> {
    return this.administrationApiUserService.getMeUserItem({ id: 'me' }).pipe(
      map((user: UserUserMe) => {
        user.languageId = user.languageId!.replace('_', '-') as Language;
        return user;
      }),
      map(user => {
        if (user.languageId === Language.LO_US) {
          user.languageId = Language.LOL_US;
        }
        return user;
      }),
      tap(user => {
        this.userStore.setUser(user);
        this.localeStore.setLocale(user.languageId as Language);
      }),
      catchError(error => {
        this.router.navigate(['oauth-error'], { queryParams: { message: error?.message, status: error?.status } });
        return of(null);
      })
    );
  }

  private loadUserSettings(): Observable<UserStorageEntity[]> {
    return forkJoin([
      this.userStorageService.getUserStorageCollection().pipe(catchError(() => of([] as UserStorageEntity[]))),
      this.widgetsLandingPageService.getLandingPageCollection().pipe(catchError(() => of([] as LandingPageEntity[])))
    ]).pipe(
      map(([settings, landingPage]) => {
        if (landingPage.length) {
          const { id, pinboardId } = landingPage[0];
          if (pinboardId) {
            settings.push({
              id,
              name: 'landingPage',
              type: 'landingPage',
              values: [pinboardId]
            });
          }
        }
        return settings;
      }),
      tap(settings => {
        const temperatureItem: UserStorageEntity | undefined = settings.find(
          s => s.type === UserSetting.TemperatureUnit
        );
        const distanceItem: UserStorageEntity | undefined = settings.find(s => s.type === UserSetting.DistanceUnit);
        const landingPageItem: UserStorageEntity | undefined = settings.find(s => s.type === UserSetting.LandingPage);
        const landingPage: LandingPageEntity | null = landingPageItem
          ? {
              id: landingPageItem.id,
              pinboardId: landingPageItem.values[0] //the pinboard id is always the first value of the array.
            }
          : null;
        const themeItem: UserStorageEntity | undefined = settings.find(s => s.type === UserSetting.Theme);
        const theme =
          themeItem?.name && Object.values(Theme).includes(themeItem?.name as Theme)
            ? themeItem
            : this.userSettingsStore.themeEntity();
        const analysisFilters: UserStorageEntity | undefined =
          settings.find(s => s.type === UserSetting.AnalysisOverviewFilter) ??
          this.userSettingsStore.analysisFiltersEntity();
        this.userSettingsStore.setLandingPageEntity(landingPage);
        this.userSettingsStore.setThemeEntity(theme);
        this.userSettingsStore.setAnalysisFiltersEntity(analysisFilters);

        if (temperatureItem) {
          this.userSettingsStore.setTemperatureUnitEntity(temperatureItem);
        }
        if (distanceItem) {
          this.userSettingsStore.setDistanceUnitEntity(distanceItem);
        }
      })
    );
  }

  private loadCurrentPortal(id: string): Observable<PortalEntity> {
    return this.portalService.getPortalItem({ id }).pipe(tap(portal => this.portalSettingsStore.setPortal(portal)));
  }

  private loadPortalSettings(ffufId: number): Observable<ProfitcenterField[]> {
    return this.passProfitcenterApiService
      .getFieldsByProfitcenterProfitcenterField({
        profitcenterId: ffufId
      })
      .pipe(
        map(profitCenterFields =>
          profitCenterFields.filter(
            field =>
              Object.values(PassPortalFields)
                .map(key => key.toLowerCase())
                .indexOf(field.fieldId.toLowerCase()) > -1
          )
        ),
        tap(fields => {
          const portalColors = fields.find(s => s.fieldId === PassPortalFields.PortalColors);
          if (portalColors) {
            const parsed = handleJSON<{ lightPrimaryColor: string; darkPrimaryColor: string }>(
              portalColors.fieldValue!
            );
            if (parsed && parsed.darkPrimaryColor && parsed.lightPrimaryColor) {
              this.portalSettingsStore.setThemeColors(portalColors.id!, {
                darkPrimaryColor: parsed.darkPrimaryColor,
                lightPrimaryColor: parsed.lightPrimaryColor
              });
            }
          }
          const automaticPlantAssignation = fields.find(f => f.fieldId === PassPortalFields.AutomaticPlantAssignation);
          if (automaticPlantAssignation) {
            this.portalSettingsStore.setAutomaticPlantAssignation(
              automaticPlantAssignation.id!,
              automaticPlantAssignation.fieldValue === 'true'
            );
          }
        })
      );
  }
}
