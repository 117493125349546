import { type Routes } from '@angular/router';
import { canAccessProtectedRoutesGuard } from './guards/can-access-protected-routes.guard';

export const routes: Routes = [
  {
    path: '',
    canActivate: [canAccessProtectedRoutesGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./layout/routes').then(layout => layout.routes)
      }
    ]
  },
  {
    path: 'print',
    canActivate: [canAccessProtectedRoutesGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./print/routes').then(print => print.routes)
      }
    ]
  },
  {
    path: 'public',
    loadChildren: () => import('./public/routes').then(pub => pub.routes)
  },
  {
    path: 'oauth-error',
    loadChildren: () => import('./pages/oauth-error/routes').then(m => m.routes)
  },
  {
    path: '**',
    redirectTo: '/'
  }
];
