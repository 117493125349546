import { inject } from '@angular/core';
import { type CanActivateFn } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

export const canAccessProtectedRoutesGuard: CanActivateFn = () => {
  const oauthService = inject(OAuthService);

  if (oauthService.hasValidAccessToken()) {
    return true;
  } else {
    oauthService.initCodeFlow();
    return false;
  }
};
