/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiErrorInterceptor } from './api-error.interceptor';
import { MigrationTokenReloadInterceptor } from './migration-token-reload.interceptor';
import { NgswBypassInterceptor } from './ngsw-bypass.interceptor';
import { Oauth2Interceptor } from './oauth2.interceptor';
import { PublicPinboardInterceptor } from './public-pinboard.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: Oauth2Interceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: NgswBypassInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: MigrationTokenReloadInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: PublicPinboardInterceptor, multi: true }
];
