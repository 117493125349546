import { type HttpClient } from '@angular/common/http';
import { type TranslateLoader } from '@ngx-translate/core';
import { ConfigProperty, type ConfigService } from '@sds/shared';

export function translateLoaderFactory(http: HttpClient, configService: ConfigService) {
  return new SdsTranslateLoader(http, configService);
}

export class SdsTranslateLoader implements TranslateLoader {
  private crowdinPrefix: string;
  private crowdinSuffix = `.json?${Date.now()}`;
  private isDev = window.location.href.includes('localhost') || window.location.href.match(/pr[\d]/)?.length;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private readonly localPrefix = 'assets/i18n/',
    private readonly localSuffix = '.json'
  ) {}

  getTranslation(lang: string) {
    // the config is not available in the constructor, so we have to set it here just once
    if (!this.crowdinPrefix && !this.isDev) {
      this.crowdinPrefix = (this.configService.get(ConfigProperty.CrowdinDistributionUrl) as string).replace(
        '{hash}',
        this.configService.get(ConfigProperty.CrowdinDistributionHash)
      );
    }

    return this.crowdinPrefix
      ? this.http.get(`${this.crowdinPrefix}${lang}${this.crowdinSuffix}`)
      : this.http.get(`${this.localPrefix}${lang}${this.localSuffix}`);
  }
}
