import * as i0 from '@angular/core';
import { NgModule } from '@angular/core';
import * as i1 from '@angular/router';
import { NavigationStart, NavigationError, NavigationEnd, NavigationCancel, RouterModule } from '@angular/router';
import * as i2 from '@ngx-loading-bar/core';
import { LoadingBarModule } from '@ngx-loading-bar/core';
class LoadingBarRouterModule {
  constructor(router, loader) {
    const ref = loader.useRef('router');
    router.events.subscribe(event => {
      const navState = this.getCurrentNavigationState(router);
      if (navState && navState.ignoreLoadingBar) {
        return;
      }
      if (event instanceof NavigationStart) {
        ref.start();
      }
      if (event instanceof NavigationError || event instanceof NavigationEnd || event instanceof NavigationCancel) {
        ref.complete();
      }
    });
  }
  getCurrentNavigationState(router) {
    // `getCurrentNavigation` only available in angular `7.2`
    const currentNavigation = router.getCurrentNavigation && router.getCurrentNavigation();
    if (currentNavigation && currentNavigation.extras) {
      return currentNavigation.extras.state;
    }
    return {};
  }
}
LoadingBarRouterModule.ɵfac = function LoadingBarRouterModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LoadingBarRouterModule)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.LoadingBarService));
};
LoadingBarRouterModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: LoadingBarRouterModule
});
LoadingBarRouterModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[RouterModule, LoadingBarModule], RouterModule, LoadingBarModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoadingBarRouterModule, [{
    type: NgModule,
    args: [{
      imports: [RouterModule, LoadingBarModule],
      exports: [RouterModule, LoadingBarModule]
    }]
  }], function () {
    return [{
      type: i1.Router
    }, {
      type: i2.LoadingBarService
    }];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { LoadingBarRouterModule };
